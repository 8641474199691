import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import StandardPersonioList from "./StandardPersonioListView";

import TranslationContext from "../../../Context/Translation";

const StandardPersonio = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    jobs,
    companies,
    categories,
    // ctaHeadline,
    // ctaTeaser,
    animation,
  },
}) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const [filteredJobs, setFilteredJobs] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);

  const groupJobs = useCallback(
    (company) => {
      const groupedJobs = {};

      // in case of a selected company - group jobs by filtered categories and filter by company
      if (company) {
        const newCategories = new Set(
          jobs.filter((job) => job.subcompany === company).map((job) => job.recruitingCategory),
        );

        newCategories.forEach((category) => {
          groupedJobs[category] = jobs
            .filter((job) => job.recruitingCategory === category)
            .filter((job) => job.subcompany === company);
        });

        return groupedJobs;
      }

      // initially group jobs by all existing categories without filtering them by company
      categories.forEach((category) => {
        groupedJobs[category] = jobs.filter((job) => job.recruitingCategory === category);
      });

      return groupedJobs;
    },
    [jobs, categories],
  );

  useEffect(() => {
    // initially selectedCompany will be null, so jobs will be grouped by all existing categories
    const newFilteredJobs = groupJobs(selectedCompany);

    setFilteredJobs(newFilteredJobs);
  }, [groupJobs, selectedCompany]);

  const renderListView = useCallback(() => {
    return (
      <>
        <ul className="nav nav-pills personio-list-companies">
          {[null, ...companies].map((company) => {
            return (
              <li key={`key_${company || Math.random()}`} className="nav-item">
                <a
                  href="#"
                  className={`nav-link ${company === selectedCompany ? "active" : ""}`.trim()}
                  onClick={(e) => {
                    e.preventDefault();

                    setSelectedCompany(company);
                  }}
                >
                  {company ||
                    (activeTranslation.twoLetterIsoCode === "de" ? "Alle anzeigen" : "Show all")}
                </a>
              </li>
            );
          })}
        </ul>

        <div className="mt-small">
          <StandardPersonioList
            filteredJobs={filteredJobs}
            translations={{
              jobDetails:
                activeTranslation.twoLetterIsoCode === "de"
                  ? "Jobprofil ansehen"
                  : "View job details",
            }}
          />
        </div>
      </>
    );
  }, [filteredJobs, companies, selectedCompany, activeTranslation]);

  return (
    <div className="container personio standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      {Object.keys(filteredJobs).length > 0 && <div className="mt-small">{renderListView()}</div>}
    </div>
  );
};

StandardPersonio.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardPersonio;
